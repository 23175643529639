<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Contractor</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="contractorRequest.status"
                        label="Active"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Category</label>
                    <v-combobox
                        v-model="contractorRequest.contractorCategorySelected"
                        :items="listContractorCategory"
                        item-text="contractorCategoryDescription"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Name</label>
                    <v-text-field
                        v-model="contractorRequest.name" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="'Contacts'" />
                </v-col>
            </v-row>

            <v-row>
                        
                <v-col
                    style="text-align: right; padding-bottom: 10px;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="mx-2"
                                v-on="on"
                                @click="showDialogContactAndClearFields()"
                            >
                                Add New Contact
                            </v-btn>
                        </template>
                        <span>Add New Contact</span>
                    </v-tooltip>
                </v-col>

                <v-col  
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <v-data-table
                        :headers="headersContact"
                        :items="contractorRequest.listContact"
                        no-data-text="No contact registered"
                        hide-default-footer
                    >
                        <template v-slot:item.action="{item}">
                            <ActionList 
                                :id="item.id" 
                                :showButtons="{
                                    edit: true,
                                    delete: true
                                }"
                                @customActionEdit="showDialogContactAndFillFields"
                                @confirmDelete="confirmContactDelete" />
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-dialog
                v-model="showDialogContact"
                transition="dialog-top-transition"
                persistent
                width="900"
                :fullscreen="$vuetify.breakpoint.mobile"
            >
            
                <v-card>
                    <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                        Contact
                    </v-card-title>

                    <v-card-text style="margin-top: 20px;">
                        <v-form
                            ref="formContact"
                            v-model="validFormContact"
                            lazy-validation
                        >
                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <label>Name</label>
                                    <v-text-field
                                        key="contactName"
                                        v-model="name"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Email</label>
                                    <v-text-field
                                        key="contactEmail"
                                        v-model="email"
                                        :rules=[validations.email]
                                        single-line
                                        outlined
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    <label>Phone</label>
                                    <v-text-field
                                        key="contactPhone"
                                        v-model="phone"
                                        v-mask="'+1 (###) ###-####'"
                                        single-line
                                        outlined
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <div style="width: 100%; text-align: center;">
                            <v-btn
                                class="mx-2 buttonCancelDialog v-btn-large"
                                style=""
                                outlined
                                @click="showDialogContact = false"
                            >
                                Cancel
                            </v-btn>

                            <v-btn
                                class="mx-2 v-btn-large"
                                outlined
                                @click="saveContact()"
                            >
                                Save
                            </v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <br />

            <ActionButtons
                :request="contractorRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import ContentHeader from "@/components/Layout/ContentHeader.vue";
    import { required, validEmail } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ContentHeader,
            ActionList: ActionList,
            ActionButtons
        },

        data: () => ({

            validForm: true,

            contractorRequest: {
                id: 0,
                status: 1,
                idContractorCategory: 0,
                contractorCategorySelected: null,
                name: "",
                listContact: []
            },

            listContractorCategory: [],

            //CONTACT

            validFormContact: null,

            headersContact: [
                { text: "Name", value: "name", sortable: true },
                { text: "Email", value: "email", sortable: true },
                { text: "Phone", value: "phone", sortable: true },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            showDialogContact: false,
            name: "",
            email: "",
            phone: "",
            itemContact: null,

            loading: false,
            
            validations: {
                required: required,
                email: validEmail,
            }
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {

            async getLists() {
                const listContractorCategory = await this.$store.dispatch("contractorCategoryModule/ListTypeContractorCategory");

                this.listContractorCategory = listContractorCategory.filter(item => item.id >= 1);
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("contractorModule/GetById", this.id);

                    if (response.success) {
                        this.contractorRequest = response.result;
                        this.contractorRequest.contractorCategorySelected = {
                            id: this.contractorRequest.contractorCategory,
                            contractorCategoryDescription: this.contractorRequest.contractorCategoryDescription
                        }
                    }

                }
                else {
                    this.contractorRequest.status = 1;
                }
            },

            //CONTACT
            async clearFieldsContact() {

                this.name = "";
                this.email = "";
                this.phone = "";
                this.itemContact = null;
            },

            async showDialogContactAndClearFields()  {

                this.clearFieldsContact();
                this.showDialogContact = true;
            },

            async showDialogContactAndFillFields(id) {
                this.clearFieldsContact();
                this.itemContact = this.contractorRequest.listContact.filter(con => con.id == id)[0];

                if (this.itemContact != null && this.itemContact != undefined) {
                    
                    this.name = this.itemContact.name;
                    this.email = this.itemContact.email;
                    this.phone = this.itemContact.phone;

                    this.showDialogContact = true;
                }
            },

            async saveContact() {

                await this.$refs.formContact.validate();

                if (this.validFormContact === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the contact form. Please review!");
                    return false;
                }
                else {

                    var existsContact = this.contractorRequest.listContact.filter(con => con.name === this.name && con.email == this.email);

                    if (existsContact.length > 0 && this.itemContact == null) {
                        this.showToast("error", "Warning!", "Contact already registered!");
                    }
                    else {
                        
                        if (this.itemContact == null) {
                            this.contractorRequest.listContact.push({
                                id: Math.random(),
                                name: this.name,
                                email: this.email,
                                phone: this.phone,
                                newRegister: true
                            })
                        }
                        else {
                            this.itemContact.name = this.name;
                            this.itemContact.email = this.email;
                            this.itemContact.phone = this.phone;
                        }

                        this.showDialogContact = false;
                    }
                }
            },

            confirmContactDelete(id) {

                let index = 0
                this.contractorRequest.listContact.forEach(itemContact => {
                    if (itemContact.id == id) {

                        this.contractorRequest.listContact.splice(index, 1);
                    }
                    index++;
                });
            },

            async cancel() {
                this.$router.push({ path: "/common/contractorList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    this.contractorRequest.id = this.id;

                    if (this.contractorRequest.status === null) {
                        this.contractorRequest.status = 0;
                    }

                    if (this.contractorRequest.contractorCategorySelected != null && this.contractorRequest.contractorCategorySelected != undefined) {
                        this.contractorRequest.idContractorCategory = this.contractorRequest.contractorCategorySelected.id
                    }
                    
                    //CONTACT
                    let listNewContact = this.contractorRequest.listContact.filter (adr => adr.newRegister === true);
                    if (listNewContact != null && listNewContact != undefined) {
                        listNewContact.forEach (itemContact => {
                            itemContact.id = 0
                        })
                    }

                    const result = await this.$store.dispatch("contractorModule/CreateUpdate", this.contractorRequest);

                    if (result.success === true) {
                        this.showToast("success", "Success!", result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                }
            }
        },

        async created() {
            this.showLoading();

            await this.getLists();
            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>

    .formDialog {
        border-bottom: solid 1px var(--color__cinza); 
        background-color: '#ffffff';
    }

</style>